import React from 'react';
import ReactDOM from 'react-dom/client';



import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";


import reportWebVitals from './reportWebVitals';
import Landing from "./views/examples/Landing";
import { Helmet } from 'react-helmet';
import { Navigate, Routes,   BrowserRouter,  Route  } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(




    //
    // <BrowserRouter>
    //   <Routes>
    //     <Route
    //         path="/"
    //         // exact render={(props) => <Landing {...props} />}/>
    //         Navegate to={<Landing  />} />
    //     <Route
    //         path="/landing-page"
    //         exact
    //         render={(props) => <Landing {...props} />}
    //     />
    //     <Route
    //         path="/index.php/privacy-policies"
    //         exact
    //         render={(props) => <Landing {...props} />}
    //     />
    //     {/*<Route to="/"/>*/}
    //   </Routes>
    // </BrowserRouter>
    //



// ...

// <BrowserRouter>
//     <Routes>
//         <Route path="/" Navigate to={Landing} />
//         <Route path="/landing-page" Navigate component={Landing} />
//         <Route path="/index.php/privacy-policies" Navigate component={Landing} />
//         <Route Navigate to="/" />
//     </Routes>
// </BrowserRouter>
//




// ...

<BrowserRouter>
    <Helmet>
        <title>Gabg Developer</title>
    </Helmet>
    <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/landing-page" element={<Landing />} />
        <Route path="/index.php/privacy-policies" element={<Landing />} />
        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
</BrowserRouter>


);
reportWebVitals();
