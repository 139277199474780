
import React from "react";

import {
  Container,
  Row,
  Col
} from "reactstrap";


import CardsFooter from "components/Footers/CardsFooter.js";



class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <main ref="main">
          <div className="position-relative">
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="18">
                      <h1 className="display-3 text-white"> Privacy Policies {" "} </h1>
                      <p className="lead text-white" >

                        Our Privacy Policy explains what kind of information we collect and the security of it in relation to our applications and mobile games. We invite you to take a moment of your time to familiarize yourself with our privacy practices.
                        <br/>
                        <br/>
                        BY INSTALLING OUR APPLICATIONS AND GAMES ON YOUR MOBILE DEVICE, YOU ACCEPT THE TERMS AND CONDITIONS SET FORTH IN THIS PRIVACY POLICY, INCLUDING THE POSSIBLE COLLECTION AND TREATMENT OF INFORMATION AND OTHER MONITORING TECHNOLOGIES IN YOUR DEVICE. IF YOU OR YOUR LEGAL DEVELOPER DISAGREES WITH ANY TERM PROVIDED IN THIS DOCUMENT, WE RECOMMEND NOT TO INSTALL, ACCESS AND / OR USE THE APPLICATIONS OR GAMES, REMOVING IT FROM ANY PART OF THE MOBILE DEVICE.
                        <br/>
                        <br/>
                        Our main purpose in collecting information is to provide you with a different, safe and personalized user experience, with the aim of providing better services and features that meet your needs in terms of handling our applications and games.
                        <br/>
                        <br/>
                        Personal Information
                        <br/>
                        <br/>
                        Gabg Developer: does not collect any personal information about you or another person. "Personal information" is personal identification, your name, email address, physical address, calendar entries, contact entries, files, photos, videos, music, etc.

                        <br/>
                        <br/>

                        Non-personal information

                        <br/>
                        <br/>

                        Gabg Developer: collects non-personal information about the use of our applications or games. "Non-personal information" is anonymous information such as the type of mobile device you use, the IP address of your mobile device, the mobile device ID, the mobile operating system, and information about how you use the applications And games.

                        <br/>
                        <br/>

                        How we use the information we collect
                        <br/>
                        <br/>

                        Personal Information: We do not collect or use any personal or confidential information about you or another person.
                        <br/>
                        <br/>

                        Non-personal information: Non-personal information is used internally to monitor and improve the Services, conduct analysis of our users' behavior, measure demographics and user interests, describe our services to third parties as advertisers, and analyze how and where best to use our resources. We do not combine non-personal information with personal information (such as combining your name with your unique user device number).
                        <br/>
                        <br/>

                        How we share the information we collect

                        <br/>
                        <br/>

                        Personal information: We do not collect or share personal information in any way about the person.
                        <br/>
                        <br/>

                        Non-Personal Information: We accept ads from third-party ad networks that can be displayed in our apps or games. We may also allow the advertising entities to collect non-personal information within the Services that they may share with us, including the device identifier, device type, device brand, device model, network type, Device and IP address.
                        <br/>
                        <br/>

                        Ad units can also collect non-personal information related to ad performance, such as how often an ad is displayed, how long an ad is viewed, and any clicks on an ad.
                        <br/>
                        <br/>

                        Links to Third Party Services
                        <br/>
                        <br/>

                        Applications may contain links to other online services, including third-party ads. If you choose to click on one of these other online services, please note that any information you provide will be subject to the privacy policy and other terms and conditions of those services and not to our Privacy Policy. We do not control the services of third parties and the fact that a link to said website or service appears in the Applications does not mean that we approve their policies or practices related to the user's information. Before providing any information to any third party service, we recommend that you review the privacy policy and other terms and conditions of that service. You agree that Gabg Developer shall have no liability for any matter relating to a third party service to which you provide information, including its collection and handling of such information.
                        <br/>
                        <br/>

                        If you have any questions or comments about this Policy or privacy practices, or to report any violation of the policy or abuse of one of our applications or games, please contact us at gabg.developer@gmail.com Google Admob. Your privacy policy can be found here:
                        <br/>
                        <br/>
                        http://www.admob.com/home/privacy.
                        <br/>
                        <br/>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
          </div>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
